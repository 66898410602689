import * as React from "react"

import Button from "@mui/material/Button"
import CloseIcon from "@mui/icons-material/Close"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import IconButton from "@mui/material/IconButton"
import LabelImportantIcon from "@mui/icons-material/LabelImportant"
import { styled } from "@mui/material/styles"

import PropTypes from "prop-types"
import CustomList from "./CustomList"
import { pageSetting } from "../helpers/theme"
import { capitalFirstLetter } from "../helpers/common"

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}))

const BootstrapDialogTitle = props => {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      <div css={{width: '90%'}}>{children}</div>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
}

export default function CustomizedDialogs({ open, handleClose, content }) {
  const { subtitle, subContent } = content
  const array = subContent.split(".")

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          css={{
            ...pageSetting.text,
            color: pageSetting.footer,
            fontWeight: 700,
          }}
        >
          {capitalFirstLetter(subtitle)}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <CustomList
            items={array}
            icon={<LabelImportantIcon css={{ color: pageSetting.attention }} />}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>ok</Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  )
}
