import React from "react"

import {
  Card,
  CardContent,
  Typography,
  Button,
  CardActions,
} from "@mui/material"
import { pageSetting, palette, theme } from "../helpers/theme"

const CustomCard = ({ title, icon, text, style, action }) => {
  return (
    <Card key={title} sx={styles.card(text, style?.bg)}>
      <div css={styles.content(text)}>
        {icon}
        <CardContent>
          <Typography variant="h5" sx={styles.title(style?.fontColor)}>
            {title.toUpperCase()}
          </Typography>
          <Typography variant="body2" sx={styles.text}>
            {text}
          </Typography>
        </CardContent>
      </div>
      {action && (
        <CardActions>
          <Button
            size="large"
            variant="text"
            css={style.btn}
            onClick={action.function}
          >
            {action.text}
          </Button>
        </CardActions>
      )}
    </Card>
  )
}

const styles = {
  root: {},
  card: (text, style) => ({
    height: 320,
    width: 320,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: !text && "center",
    margin: `${theme.gutterBig}px 0`,
    paddingTop: text && theme.gutterSmall,
    boxShadow: `0 0 5px ${palette.darkGrey}`,
    transition: "all .5s ease-out",
    "&:hover": {
      transform: "scale3d(1.1, 1.1, 1)",
      boxShadow: `0 0 20px ${palette.darkGrey}`,
    },
    ...style,
  }),
  title: style => ({
    p: 2,
    ...pageSetting.text,
    ...style,
  }),
  content: text => ({
    display: !text && "flex",
    alignItems: !text && "center",
    height: !text && "100%",
  }),
}

export default CustomCard
