import React, { useState } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

import { pageSetting, palette, theme } from "../helpers/theme"

import content from "../helpers/content.json"
import SectionTitle from "../components/SectionTitle"
import CustomCard from "../components/CustomCard"
import CustomDialog from "../components/Dialog"

const Accauntant = () => {
  const title = content.ofert.content.services.title
  const arrayContent = content.ofert.content.services.blocks
  const colors = "#03313F #044c62 #03313F #044c62".split(" ")

  const [open, setOpen] = useState(false)
  const [index, setIndex] = useState(0)

  const handleClickOpen = i => () => {
    setIndex(i)
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Layout>
      <Seo title={title} />
      <div css={styles.root}>
        <SectionTitle title={title} />
        <div css={styles.content}>
          <div css={styles.cardsBox}>
            {arrayContent.map((obj, i) => (
              <CustomCard
                title={obj.subtitle}
                key={obj.subtitle}
                style={{
                  bg: {
                    background: `linear-gradient(${colors[i]}, ${
                      colors[i + 1]
                    })`,
                    opacity: ["90%", "100%", "90%"][i],
                  },
                  fontColor: { ...pageSetting.textLight },
                  btn: {
                    color: "#F9B87B",
                    fontWeight: 700,

                    "&:hover": {
                      background: palette.darkGrey,
                    },
                  },
                }}
                action={{ text: "Więcej", function: handleClickOpen(i) }}
              />
            ))}
          </div>
        </div>
      </div>
      <CustomDialog
        open={open}
        handleClose={handleClose}
        content={arrayContent[index]}
      />
    </Layout>
  )
}

const styles = {
  root: {
    marginTop: theme.gutterBig * 6,
  },
  content: {},
  cardsBox: {
    display: "flex",
    justifyContent: "space-evenly",
    flexWrap: "wrap",
  },
}

export default Accauntant
